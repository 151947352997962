body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#github-chart{
  max-width: 100%;
}

#skills .cv-block-content > div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

#contact.cv-block{
  background-image: url(/src/images/mountain2_1500px.jpg);
  background-size: cover;
  color: white;
  background-position: 50% 50%;
}

#contact .cv-block-content{
  background-color: transparent;
}

#contact .divider{
  display:none;
}

#contact a:link {color:white;}
#contact a:visited {color:white;}
#contact a:hover {color:white;}
#contact a:active {color:white;}

@media (max-width: 992px){
  #about-me .title{
    margin-top:60px;
  }
  #img-me-wrapper{
    text-align: center;
  }
  #contact .row > div{
    text-align: center;
  }
}


