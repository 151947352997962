@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Sanchez&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&subset=latin-ext');

:root {
  --accent-color:#E91E63;
  --accent-color-transparent: #8f133d;
  --accent-color-light: #ff447f;
}

html,body{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.color-accent{
  color: var(--accent-color);
}

.underline{
  text-decoration: underline;
}

#background{
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background-image: url(/src/images/flowers_1000px.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#content{
  position: relative;
}

.divider{
  width: 100%;
  height: 2px;
  max-height:2px;
  background-color: var(--accent-color);
}

.material-icons{
  vertical-align: text-bottom;
}

#header{
  height: 100vh;
  position: relative;
  background-image: url(/src/images/mountain2_1500px.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#title-text, #title-sub-text{
  font-size:max(10px,3vw);
  color: #FFFAFA;
  font-family: 'Sanchez', serif;
}

#title-sub-text{
  font-size:max(7px,3vw);
}


a:link {color:#393939;}
a:visited {color:#393939;}
a:hover {color:#393939;}
a:active {color:#393939;}
