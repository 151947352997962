.cv-block{
    width: 100%;
    padding: 5vw calc(max(10vw, 130px) + 30px) 5vw calc(max(10vw, 130px) + 30px);
    /*min-height: 100vh;*/
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cv-block-content{
    width: 100%;
    padding: 20px;
    background-color: #ffffffCC;
}

.cv-block.bg-white{

}

.cv-block.bg-floral{
    background-color: #ffffff00;
}

.cv-block .title{
    margin-bottom:30px;
}

@media (max-width: 992px){
    .cv-block {
        padding: 5vw;
    }
    .cv-block .row {
        margin: 35px 0 35px 0;
    }
    .cv-block .row > div {
        margin: 10px 0 10px 0;
    }
    .cv-block-content{
        text-align: left;
    }
}