.skill-list{
    max-width:300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-list .list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-list.align-right .list{
    align-items: flex-end;
}

.skill-item{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.skill-item .skill-bar-outer{
    margin-left: 15px;
}

.skills-title{
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
}

.skill-list.languages .skill-name{
    font-weight: bold;
}