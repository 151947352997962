.experience-item {

}

.experience-item .title{
    color:#666666;
    font-weight:bold;
    margin-bottom:10px;
}

.experience-item .subtitle{
    font-weight:bold;
    text-align:left;
    margin-bottom:10px;
}

.experience-item .place{

}

.experience-item .date{

}

.experience-item .description{
    text-align:left;
    white-space: pre-line;
}

.row{
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 3vw;
    padding-right: 3vw;
}