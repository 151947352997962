@import url(https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Sanchez&subset=latin-ext);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700&subset=latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#github-chart{
  max-width: 100%;
}

#skills .cv-block-content > div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

#contact.cv-block{
  background-image: url(../../static/media/mountain2_1500px.5de72be3.jpg);
  background-size: cover;
  color: white;
  background-position: 50% 50%;
}

#contact .cv-block-content{
  background-color: transparent;
}

#contact .divider{
  display:none;
}

#contact a:link {color:white;}
#contact a:visited {color:white;}
#contact a:hover {color:white;}
#contact a:active {color:white;}

@media (max-width: 992px){
  #about-me .title{
    margin-top:60px;
  }
  #img-me-wrapper{
    text-align: center;
  }
  #contact .row > div{
    text-align: center;
  }
}



:root {
  --accent-color:#E91E63;
  --accent-color-transparent: #8f133d;
  --accent-color-light: #ff447f;
}

html,body{
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  scroll-behavior: smooth;
}

.color-accent{
  color: #E91E63;
  color: var(--accent-color);
}

.underline{
  text-decoration: underline;
}

#background{
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
  background-image: url(../../static/media/flowers_1000px.3a39b9c7.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

#content{
  position: relative;
}

.divider{
  width: 100%;
  height: 2px;
  max-height:2px;
  background-color: #E91E63;
  background-color: var(--accent-color);
}

.material-icons{
  vertical-align: text-bottom;
}

#header{
  height: 100vh;
  position: relative;
  background-image: url(../../static/media/mountain2_1500px.5de72be3.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#title-text, #title-sub-text{
  font-size:max(10px,3vw);
  color: #FFFAFA;
  font-family: 'Sanchez', serif;
}

#title-sub-text{
  font-size:max(7px,3vw);
}


a:link {color:#393939;}
a:visited {color:#393939;}
a:hover {color:#393939;}
a:active {color:#393939;}

.typewriter h1{
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid transparent; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    -webkit-animation:
            typing 2s steps(12, end),
            blink-caret .15s step-end 14;
            animation:
            typing 2s steps(12, end),
            blink-caret .15s step-end 14;
}

.typewriter h4{
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: .15em solid transparent; /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em;
    -webkit-animation:
            typing 1.5s steps(10, end),
            blink-caret .15s step-end 12;
            animation:
            typing 1.5s steps(10, end),
            blink-caret .15s step-end 12;
    -webkit-animation-delay: 2.4s;
            animation-delay: 2.4s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;

}

/* The typing effect */
@-webkit-keyframes typing {
    from { width: 0 }
    to { width: 100% }
}
@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--accent-color) }
}
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--accent-color) }
}
.cv-block{
    width: 100%;
    padding: 5vw calc(max(10vw, 130px) + 30px) 5vw calc(max(10vw, 130px) + 30px);
    /*min-height: 100vh;*/
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.cv-block-content{
    width: 100%;
    padding: 20px;
    background-color: #ffffffCC;
}

.cv-block.bg-white{

}

.cv-block.bg-floral{
    background-color: #ffffff00;
}

.cv-block .title{
    margin-bottom:30px;
}

@media (max-width: 992px){
    .cv-block {
        padding: 5vw;
    }
    .cv-block .row {
        margin: 35px 0 35px 0;
    }
    .cv-block .row > div {
        margin: 10px 0 10px 0;
    }
    .cv-block-content{
        text-align: left;
    }
}
#cv-menu-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
}

#cv-menu{
    position: -webkit-sticky;
    position: sticky;
    top: 2.5vw;
    text-align: center;
    font-size: 1em;
    font-family: 'Sanchez', serif;
    max-height: 90vw;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    left: calc(100% - max(10vw, 130px) - 15px);
    background-color: transparent;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: nowrap;
}
.cv-menu-item{
    display: block;
    color:#FFFAFA !important;
    width: 10vw;
    min-width: 130px;
    min-height: 50px;
    padding: 15px;
    cursor: pointer;
    -webkit-transition-property: heigh, min-height, width, min-width, padding, font-size;
    transition-property: heigh, min-height, width, min-width, padding, font-size;
    -webkit-transition-duration: 300ms, 300ms, 300ms, 300ms, 300ms, 900ms;
            transition-duration: 300ms, 300ms, 300ms, 300ms, 300ms, 900ms;
    white-space: pre-line;
    background-color: #393939;
}

.cv-menu-item:hover{
    background-color: var(--accent-color-transparent);
}

.cv-menu-item.selected{
    background-color: var(--accent-color);
}


#cv-menu-arrow{
    position: absolute;
    left: calc(100% - max(10vw, 130px) - 15px);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cv-menu a:hover, #cv-menu a:active{
    text-decoration: none;
}

#cv-menu-expand{
    display: none;
}

@media (max-width: 992px) {
    #cv-menu-wrapper.expanded{
        z-index: 9;
    }
    #cv-menu{
        min-width: 90px;
        left: calc(100% - max(10vw, 90px) - 15px);
    }
    #cv-menu:not(.expanded){
        background-color: transparent;
    }
    .cv-menu-item{
        min-width: 90px;
        font-size: 10px;
    }
    #cv-menu-arrow{
        left: calc(100% - 50px - 15px);
    }
    #cv-menu-expand{
        display: block;
        background-color: #393939;
    }
    #cv-menu.expanded #cv-menu-expand{
        background-color: var(--accent-color);
    }
    #cv-menu #cv-menu-expand, #cv-menu-arrow{
        width: 50px;
        min-width: 50px;
    }
    #cv-menu:not(.expanded) .cv-menu-item:not(#cv-menu-expand){
        width: 50px;
        min-width: 50px;
        height: 0;
        min-height: 0;
        padding: 0;
        overflow: hidden;
        font-size: 1px;
    }
    #cv-menu .material-icons{
        font-size: 20px;
    }
}
.experience-item {

}

.experience-item .title{
    color:#666666;
    font-weight:bold;
    margin-bottom:10px;
}

.experience-item .subtitle{
    font-weight:bold;
    text-align:left;
    margin-bottom:10px;
}

.experience-item .place{

}

.experience-item .date{

}

.experience-item .description{
    text-align:left;
    white-space: pre-line;
}

.row{
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-left: 3vw;
    padding-right: 3vw;
}
.bullet-list{
    text-align: left;
}

.bullet-list-title{
    text-decoration: underline;
}
#img-me{
    max-width:80%;
    max-height: 80%;
}

.skill-list{
    max-width:300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-list .list{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-list.align-right .list{
    align-items: flex-end;
}

.skill-item{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.skill-item .skill-bar-outer{
    margin-left: 15px;
}

.skills-title{
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 20px;
}

.skill-list.languages .skill-name{
    font-weight: bold;
}
.skill-bar-outer{
    border-radius: 5px;
    position: relative;
    width:100px;
    padding:2px;
    background-color: var(--accent-color);
    height: 14px;
    -webkit-transition: padding 200ms ease;
    transition: padding 200ms ease;
}
.skill-bar-inner{
    border-radius: 3px;
    height: 100%;
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
            135deg,
            #ffffff,
            #ffffff 2px,
            #ffffff00 2px,
            #ffffff00 4px
    );
}

.skill-bar-outer:hover{
    padding:3px;

}

.skill-bar-outer:hover .skill-bar-inner{
    -webkit-animation: bar-animation 3s linear infinite;
            animation: bar-animation 3s linear infinite;
    animation-direction: reverse;
}

@-webkit-keyframes bar-animation {
    100% {
        background-position: 100% 100%;
    }
}

@keyframes bar-animation {
    100% {
        background-position: 100% 100%;
    }
}
.contact-icon{
    height: 64px;
    width: 64px;
    display: inline-block;
}
.contact-icon object{
    pointer-events: none;
}
