#cv-menu-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
}

#cv-menu{
    position: sticky;
    top: 2.5vw;
    text-align: center;
    font-size: 1em;
    font-family: 'Sanchez', serif;
    max-height: 90vw;
    width: min-content;
    left: calc(100% - max(10vw, 130px) - 15px);
    background-color: transparent;
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-wrap: nowrap;
}
.cv-menu-item{
    display: block;
    color:#FFFAFA !important;
    width: 10vw;
    min-width: 130px;
    min-height: 50px;
    padding: 15px;
    cursor: pointer;
    transition-property: heigh, min-height, width, min-width, padding, font-size;
    transition-duration: 300ms, 300ms, 300ms, 300ms, 300ms, 900ms;
    white-space: pre-line;
    background-color: #393939;
}

.cv-menu-item:hover{
    background-color: var(--accent-color-transparent);
}

.cv-menu-item.selected{
    background-color: var(--accent-color);
}


#cv-menu-arrow{
    position: absolute;
    left: calc(100% - max(10vw, 130px) - 15px);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

#cv-menu a:hover, #cv-menu a:active{
    text-decoration: none;
}

#cv-menu-expand{
    display: none;
}

@media (max-width: 992px) {
    #cv-menu-wrapper.expanded{
        z-index: 9;
    }
    #cv-menu{
        min-width: 90px;
        left: calc(100% - max(10vw, 90px) - 15px);
    }
    #cv-menu:not(.expanded){
        background-color: transparent;
    }
    .cv-menu-item{
        min-width: 90px;
        font-size: 10px;
    }
    #cv-menu-arrow{
        left: calc(100% - 50px - 15px);
    }
    #cv-menu-expand{
        display: block;
        background-color: #393939;
    }
    #cv-menu.expanded #cv-menu-expand{
        background-color: var(--accent-color);
    }
    #cv-menu #cv-menu-expand, #cv-menu-arrow{
        width: 50px;
        min-width: 50px;
    }
    #cv-menu:not(.expanded) .cv-menu-item:not(#cv-menu-expand){
        width: 50px;
        min-width: 50px;
        height: 0;
        min-height: 0;
        padding: 0;
        overflow: hidden;
        font-size: 1px;
    }
    #cv-menu .material-icons{
        font-size: 20px;
    }
}