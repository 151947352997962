.skill-bar-outer{
    border-radius: 5px;
    position: relative;
    width:100px;
    padding:2px;
    background-color: var(--accent-color);
    height: 14px;
    transition: padding 200ms ease;
}
.skill-bar-inner{
    border-radius: 3px;
    height: 100%;
    background-size: 200% 200%;
    background-image: repeating-linear-gradient(
            135deg,
            #ffffff,
            #ffffff 2px,
            #ffffff00 2px,
            #ffffff00 4px
    );
}

.skill-bar-outer:hover{
    padding:3px;

}

.skill-bar-outer:hover .skill-bar-inner{
    animation: bar-animation 3s linear infinite;
    animation-direction: reverse;
}

@keyframes bar-animation {
    100% {
        background-position: 100% 100%;
    }
}